@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background: #000300;
  margin: 0;
  font-family: "Poppins";
}
